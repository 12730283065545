import { useState, forwardRef, useImperativeHandle, useRef } from 'react'
import { useForm } from 'react-hook-form'

import { yupResolver } from '@hookform/resolvers/yup'
import { Grid, makeStyles } from '@material-ui/core'
import PlayArrowIcon from '@material-ui/icons/PlayArrow'
import LastPageIcon from '@material-ui/icons/LastPage'
import { KeyboardTimePicker } from '@material-ui/pickers'

import { addClientSchema } from '@utils/schemas'

const useStyles = makeStyles(() => ({
  container: {
    paddingTop: 15,
    paddingBottom: 15,
  },

  oneHeader: {
    backgroundColor: '#f1f1f1',
    padding: 5,
    width: '15%',
    textAlign: 'center',
    borderRadius: 4,
    fontWeight: 500,
    fontSize: 18,
  },
}))

type OneHoursGroup = {
  name: string
  startHour: { value: string | number; label: string }[]
  endHour: { value: string | number; label: string }[]
  isActive: boolean
  control: any
}

type Values = {
  Morning: OneHoursGroup
  Afternoon: OneHoursGroup
  Evening: OneHoursGroup
  Night: OneHoursGroup
}

export const ConfigHoursGroupForm = forwardRef((_, ref) => {
  const { handleSubmit, errors, control, watch } = useForm<Values>({
    resolver: yupResolver(addClientSchema) as any,
    defaultValues: {},
  })

  const classes = useStyles()

  console.log(watch())
  console.log(errors)

  const onSubmit = () => {
    // console.log(values)
    // const newClient: ClientDto = {
    //   name: values.startDate,
    //   siretNumber: values.salle.substring(0, values.salle.length - 5),
    //   sirenNumber: values.salle,
    //   companyStatus: values.room,
    //   streetAddress: values.doctor,
    //   postalCode: values.preferedDoctor,
    //   city: values.type,
    //   state: values.type,
    //   country: values.study,
    //   email: values.comments,
    //   phoneNumber: values.emergency,
    //   //fax: values.hospitalized,
    //  // sites: [],
    // }
    // dispatch(addClient({ body: newClient }))
  }
  useImperativeHandle(ref, () => ({
    submit() {
      handleSubmit(onSubmit)()
    },
  }))

  return (
    <Grid container spacing={1} className={classes.container}>
      <Grid container justifyContent="space-between" direction="row">
        {headerConfigHours.map((value, i) => (
          <span key={i} className={classes.oneHeader}>
            {value}
          </span>
        ))}
      </Grid>
      <Grid container justifyContent="space-between" direction="column">
        {dataConfigHours.map((value, i) => {
          return (
            <OneConfigHours
              key={i}
              control={control}
              name={value.name}
              startHour={value.startHours}
              endHour={value.endHours}
              isActive={value.active}
            />
          )
        })}
      </Grid>
    </Grid>
  )
})

const OneConfigHours = ({ name }: OneHoursGroup) => {
  return (
    <Grid
      container
      className="one-hours-group"
      justifyContent="space-between"
      direction="row"
    >
      <span className="name">{name}</span>
      {[0, 1, 2, 3, 4].map((_, index) => (
        <StartEndTime key={index} />
      ))}
    </Grid>
  )
}

// (, index) => <StartEndTime key={index} />
const StartEndTime = () => {
  const inputRef = useRef<HTMLDivElement>(null)
  // The first commit of Material-UI
  const [startDate, setStartDate] = useState<Date | null>(
    new Date('2014-08-18T21:11:54'),
  )

  const [endDate, setEndDate] = useState<Date | null>(
    new Date('2014-08-18T21:11:54'),
  )

  // useEffect(() => {

  // }, []);

  const handleClick = () => {
    if (inputRef && inputRef.current) {
      console.log('click')
    }
    console.log(inputRef)
    //inputRef.current.click()
  }

  const handleDateStartChange = (date: any) => {
    setStartDate(date)
  }

  const handleDateEndChange = (date: any) => {
    setEndDate(date)
  }

  return (
    <div className="select-display-time">
      <div onClick={handleClick}>
        <PlayArrowIcon
        //color="primary"
        />
        <KeyboardTimePicker
          className="eh-time-picker start"
          margin="normal"
          id="time-picker"
          value={startDate}
          onChange={handleDateStartChange}
          KeyboardButtonProps={{
            'aria-label': 'change time',
          }}
        />
      </div>
      <div style={{ marginTop: 5 }}></div>
      <div>
        <LastPageIcon
        //color="secondary"
        />
        <KeyboardTimePicker
          className="eh-time-picker end"
          margin="normal"
          id="time-picker"
          value={endDate}
          onChange={handleDateEndChange}
          KeyboardButtonProps={{
            'aria-label': 'change time',
          }}
        />
      </div>
    </div>
  )
}

const dataConfigHours = [
  {
    name: 'Morning',
    startHours: [
      { value: 1, label: '8h' },
      { value: 2, label: '9h' },
      { value: 3, label: '10h' },
      { value: 4, label: '11h' },
    ],
    endHours: [
      { value: 1, label: '9h' },
      { value: 2, label: '10h' },
      { value: 3, label: '11h' },
      { value: 4, label: '12h' },
    ],

    active: true,
  },

  {
    name: 'Afternoon',
    startHours: [
      { value: 1, label: '12h' },
      { value: 2, label: '13h' },
      { value: 3, label: '14h' },
      { value: 4, label: '15h' },
    ],
    endHours: [
      { value: 1, label: '13h' },
      { value: 2, label: '14h' },
      { value: 3, label: '15h' },
      { value: 4, label: '16h' },
    ],

    active: true,
  },

  {
    name: 'Evening',
    startHours: [
      { value: 1, label: '8h' },
      { value: 2, label: '9h' },
      { value: 3, label: '10h' },
      { value: 4, label: '11h' },
    ],
    endHours: [
      { value: 1, label: '9h' },
      { value: 2, label: '10h' },
      { value: 3, label: '11h' },
      { value: 4, label: '12h' },
    ],

    active: true,
  },

  {
    name: 'Night',
    startHours: [
      { value: 1, label: '8h' },
      { value: 2, label: '9h' },
      { value: 3, label: '10h' },
      { value: 4, label: '11h' },
    ],
    endHours: [
      { value: 1, label: '9h' },
      { value: 2, label: '10h' },
      { value: 3, label: '11h' },
      { value: 4, label: '12h' },
    ],

    active: true,
  },
]

const headerConfigHours = ['Site', 'Mon', 'Tue', 'Web', 'Thu', 'Fri']
