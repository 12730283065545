import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Box, Button, Grid, IconButton } from '@material-ui/core'
// ICON
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import {
  AutocompleteRppsInput,
  MultipleSelectInput,
  SelectInput,
  TextInput,
  TimeInput,
} from '@components/inputs'

import {
  addActions,
  removeActions,
  updateActions,
} from '@state/reducers/edamReducer'
import { RootState } from '@state/reducers/root'
import { EcSpaceHorizontal, EcSpaceVertical } from '@utils/Spacing'
import {
  genre,
  macroActionSelect,
  macroConditionSelect,
  macroDaySelect,
  macroModalitySelect,
} from '@utils/constants'
import { AutocompleteFinessInput } from '../../inputs/AutocompletFinessInput'

import { LineVertical } from './common/utils'
import { IActionPerformProps } from './common/Interface'

export const Action = ({ register, control, errors }: IActionPerformProps) => {
  // const [actionSelected, setActionSelected] = React.useState('1');
  const [listActionLocal, setListActionLocal] = useState<Array<any>>([])
  const dispatch = useDispatch()

  const { listActions } = useSelector(({ edam }: RootState) => edam)

  useEffect(() => {
    setListActionLocal([])
    setTimeout(() => {
      setListActionLocal(listActions)
    }, 1)
  }, [listActions])

  return (
    <Box pl={5}>
      <Grid container spacing={2}>
        {listActionLocal?.map((value, i) => {
          return (
            <OneAction
              key={i}
              content={value}
              register={register}
              control={control}
              errors={errors}
            />
          )
        })}

        <Box width="100%" position="relative">
          <LineVertical h={'30px'} left={-12} />
          <LineVertical h={'1px'} w={14} bottom={'35%'} left={-12} />

          <EcSpaceVertical size={10} />
          <Button
            color="primary"
            startIcon={<AddCircleOutlineIcon />}
            onClick={() => {
              dispatch(addActions({ key: `Action ${listActions.length + 1}` }))
              // setListAction(listAction => [...listAction, `'Action' ${listAction.length + 1}`]);
            }}
          >
            Add Sub-condition
          </Button>
        </Box>
      </Grid>
    </Box>
  )
}

const OneAction = ({
  register,
  control,
  errors,
  content = null,
}: IActionPerformProps) => {
  const dispatch = useDispatch()

  return (
    <Grid item xs={12}>
      <Box display="flex" alignItems="center" justifyContent="flex-start">
        <Grid item xs={3} style={{ position: 'relative' }}>
          <LineVertical />
          <LineVertical h={'1px'} w={17} bottom={'50%'} />

          <SelectInput
            defaultValue={content?.select}
            input="action"
            label="Action"
            inputRef={register}
            control={control}
            options={macroActionSelect}
            onChange={(e) => {
              // setActionSelected(e.target.value)'
              dispatch(
                updateActions({
                  key: content.key,
                  value: getInitAction(e.target.value),
                }),
              )
            }}
          />

          <LineVertical h={'60%'} w={'1px'} />
        </Grid>

        {content?.content?.map((value: any) => {
          return (
            <>
              {value?.name === 'select' && (
                <>
                  <EcSpaceHorizontal size={15} />
                  <Grid item xs={3}>
                    <SelectInput
                      defaultValue={value?.params?.select}
                      input={value?.input}
                      label={value?.label}
                      inputRef={register}
                      control={control}
                      options={value?.params?.options}
                    />
                  </Grid>
                </>
              )}

              {value?.name === 'input' && (
                <>
                  <EcSpaceHorizontal size={15} />
                  <Grid item xs={3}>
                    <TextInput
                      input={value?.input}
                      inputRef={register}
                      errors={errors}
                    />
                  </Grid>
                </>
              )}

              {value?.name === 'time' && (
                <>
                  <EcSpaceHorizontal size={15} />
                  <Grid item xs={2}>
                    <TimeInput input={value?.input} control={control} />
                  </Grid>
                </>
              )}

              {value?.name === 'daySelect' && (
                <>
                  <EcSpaceHorizontal size={15} />
                  <Grid item xs={2}>
                    <SelectInput
                      label="Select..."
                      defaultValue="1"
                      input={value?.input}
                      inputRef={register}
                      control={control}
                      options={value?.params?.options}
                    />
                  </Grid>
                </>
              )}

              {value?.name === 'multiSelect' && (
                <>
                  <EcSpaceHorizontal size={15} />
                  <Grid item xs={3}>
                    <MultipleSelectInput
                      label="Select..."
                      input="select"
                      inputRef={register}
                      control={control}
                      options={value?.params?.options}
                    />
                  </Grid>
                </>
              )}

              {value?.name === 'autocompleteFiness' && (
                <>
                  <EcSpaceHorizontal size={15} />
                  <Grid item xs={3}>
                    <AutocompleteFinessInput
                      onSelect={(site) => {
                        console.log('AutocompleteRppsInput', site)
                        // setSelectedSite(site)
                      }}
                    />
                  </Grid>
                </>
              )}

              {value?.name === 'autocompleteRpps' && (
                <>
                  <EcSpaceHorizontal size={15} />
                  <Grid item xs={3}>
                    <AutocompleteRppsInput
                      //  autoRppsClass={classes.autoRppsClass}
                      onSelect={(doctor) => {
                        // setSelectedDoctor(doctor)
                        console.log('AutocompleteRppsInput', doctor)
                      }}
                    />
                  </Grid>
                </>
              )}
            </>
          )
        })}

        <IconButton
          aria-label="delete"
          color="secondary"
          onClick={() => {
            dispatch(removeActions(content.key))
          }}
        >
          <HighlightOffIcon />
        </IconButton>
      </Box>
    </Grid>
  )
}

const selectCondition = {
  name: 'select',
  input: 'condition',
  label: 'Condition',
  params: { select: 2, options: macroConditionSelect },
}

const selectDay = {
  name: 'daySelect',
  input: 'day',
  label: 'Day',
  params: { select: 2, options: macroDaySelect },
}

function getInitAction(select: string): Object {
  switch (select) {
    case '1':
      return {
        select: 1,
        content: [
          {
            name: 'select',
            input: 'fromSources',
            label: 'Select...',
            params: { select: 1, options: [{ value: '1', label: 'client 1' }] },
          },
        ],
      }

    case '2':
      return {
        select: 2,
        content: [
          selectCondition,
          {
            name: 'multiSelect',
            input: 'select',
            label: 'Select...',
            params: { select: 1, options: macroModalitySelect },
          },
        ],
      }

    case '3':
      return {
        select: 3,
        content: [
          selectCondition,
          {
            name: 'autocompleteFiness',
            input: 'select',
            label: 'Select...',
            params: {},
          },
        ],
      }

    case '4':
      return {
        select: 4,
        content: [
          selectCondition,
          {
            name: 'autocompleteRpps',
            input: 'select',
            label: 'Select...',
            params: {},
          },
        ],
      }

    case '5':
      return {
        select: 5,
        content: [
          selectCondition,
          {
            name: 'input',
            input: 'value',
            label: 'Select...',
            params: { select: 1, options: macroModalitySelect },
          },
        ],
      }

    case '6':
      return {
        select: 6,
        content: [
          selectCondition,
          {
            name: 'input',
            input: 'value',
            label: 'Select...',
            params: { select: 1, options: macroModalitySelect },
          },
        ],
      }

    case '7':
      return {
        select: 7,
        content: [
          selectCondition,
          {
            name: 'input',
            input: 'value',
            label: 'Select...',
            params: { select: 1, options: macroModalitySelect },
          },
        ],
      }

    case '8':
      return {
        select: 8,
        content: [
          selectDay,
          {
            name: 'time',
            input: 'select',
            label: 'Select...',
            params: { select: 1, options: macroModalitySelect },
          },
        ],
      }

    case '9':
      return {
        select: 9,
        content: [
          selectDay,
          {
            name: 'time',
            input: 'select',
            label: 'Select...',
            params: { select: 1, options: macroModalitySelect },
          },
          selectDay,
          {
            name: 'time',
            input: 'select',
            label: 'Select...',
            params: { select: 5, options: macroModalitySelect },
          },
        ],
      }

    case '10':
      return {
        select: 10,
        content: [
          selectCondition,
          {
            name: 'input',
            input: 'value',
            label: 'Select...',
            params: { select: 1, options: macroModalitySelect },
          },
        ],
      }
    case '11':
      return {
        select: 11,
        content: [
          {
            name: 'multiSelect',
            input: 'select',
            label: 'Select...',
            params: { select: 1, options: genre },
          },
        ],
      }

    default:
      return {}
  }
}
