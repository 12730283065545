import { Box } from '@material-ui/core'

interface ILine {
  h?: string | number
  w?: string | number
  bgColor?: string
  left?: string | number
  right?: string | number
  top?: string | number
  bottom?: string | number
}

export function LineVertical({
  h = '100%',
  w = '1px',
  bgColor = 'primary.main',
  left = -20,
  right,
  top,
  bottom,
}: ILine) {
  return (
    <Box
      bgcolor={bgColor}
      height={h}
      width={w}
      left={left}
      right={right}
      top={top}
      bottom={bottom}
      position="absolute"
    ></Box>
  )
}
