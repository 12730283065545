import { useDispatch } from 'react-redux'

import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Icon,
  MenuItem,
  makeStyles,
} from '@material-ui/core'
import { TextInput } from '@components/inputs'

import Text from '../Text'
import { setLanguage } from '@state/reducers/authReducer'

const useStyles = makeStyles(() => ({
  section: {
    flexGrow: 1,
  },
}))

type LanguageFormProps = {
  language: string
}

export function LanguageForm({ language }: LanguageFormProps) {
  const classes = useStyles()
  const dispatch = useDispatch()

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setLanguage(event.target.value))
  }

  //TODO put in DB
  const options = [
    { value: 'en', label: 'en' },
    { value: 'fr', label: 'fr' },
  ]

  return (
    <Card className={classes.section}>
      <CardHeader
        title={
          <Grid container direction="row" alignItems="center" spacing={2}>
            <Grid item>
              <Icon className="fa fa-globe" />
            </Grid>
            <Grid item>
              <Text text="app.profile.sections.[3].title" format />
            </Grid>
          </Grid>
        }
      />

      <Divider />
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <TextInput
              input="language"
              select
              value={language}
              onChange={onChange}
            >
              {options.map((option, index: number) => (
                <MenuItem key={index} value={option.value}>
                  <em>{option.label}</em>
                </MenuItem>
              ))}
            </TextInput>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}
