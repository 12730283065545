import { useState } from 'react'

import { Grid } from '@material-ui/core'
// ICON

import { SelectInput, TextInput } from '@components/inputs'

import { macroPerformPreFetch } from '@utils/constants'
import { IActionPerformProps } from '../common/Interface'

export const PreFetch = ({
  register,
  control,
  errors,
}: // remove,
// action
IActionPerformProps) => {
  const [fetchSelected, setFetchSelected] = useState('1')

  return (
    <Grid container spacing={2}>
      <Grid item xs={4}>
        <SelectInput
          defaultValue="1"
          input="preFetch"
          label="Select..."
          inputRef={register}
          control={control}
          options={macroPerformPreFetch}
          onChange={(e) => {
            setFetchSelected(e.target.value)
          }}
        />
      </Grid>

      {fetchSelected === '2' && (
        <>
          <Grid item xs={2}>
            <TextInput input="value" inputRef={register} errors={errors} />
          </Grid>

          <Grid item xs={2}>
            <SelectInput
              defaultValue="1"
              input="duration"
              label="Duration"
              inputRef={register}
              control={control}
              options={[
                { value: '1', label: 'Months' },
                { value: '2', label: 'Years' },
              ]}
            />
          </Grid>
        </>
      )}
    </Grid>
  )
}
