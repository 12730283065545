import { Dialog, DialogActions, DialogContent } from '@material-ui/core'
import { Button } from '@components/buttons'

import { ExceptionalOpeningForm } from '../forms'
import { DialogTitle } from '../titles'

type ExceptionalOpeningDialogProps = {
  onClose: () => void
  open: boolean
}

export function ExceptionalOpeningDialog({
  onClose,
  open,
}: ExceptionalOpeningDialogProps) {
  //const classes = useStyles();

  return (
    <Dialog
      open={open}
      // onClose={onClose}
      aria-labelledby="form-dialog-title"
      maxWidth="sm"
    >
      <DialogTitle title="Exceptional Opening" onClose={onClose} />
      <DialogContent>
        <ExceptionalOpeningForm onSubmit={() => {}} />
      </DialogContent>

      <DialogActions style={{ paddingRight: 25, paddingBottom: 15 }}>
        <Button
          text="Create Exceptional Openness"
          // onClick={handleOnSubmit}
          color="primary"
          //loading={loading}
        />
      </DialogActions>
    </Dialog>
  )
}
