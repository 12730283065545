import { useState, forwardRef, useImperativeHandle } from 'react'
import { useForm } from 'react-hook-form'

import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Grid } from '@material-ui/core'
// ICON

import { filter } from 'lodash'
import { CheckBoxInput, SelectInput, TextInput } from '@components/inputs'

import { macroPerformSelect, macroWhenSelect } from '@utils/constants'
import { EcSpaceHorizontal, EcSpaceVertical } from '@utils/Spacing'
import { addClientSchema } from '@utils/schemas'
import { CreateClientDto } from '@services/api'

import { LineVertical } from './common/utils'
import { PreFetch } from './perform/PreFetch'
import { PushToPacs, SendEmail, SendTextMeassage, ShareExam } from './perform'
import { Action } from './Action'

type MacroFormProps = {
  onSubmit: (values: CreateClientDto) => void
}
//TODO useless ?
export const MacroForm = forwardRef(({ onSubmit }: MacroFormProps, ref) => {
  const { register, handleSubmit, errors, control } = useForm<CreateClientDto>({
    resolver: yupResolver(addClientSchema),
  })

  const [listAction, setListAction] = useState<Array<any>>([])
  //const [listPerform, setListPerform] = React.useState<Array<any>>([])
  const [performSelected, setPerformSelected] = useState('3')

  const beforeSubmit = (clientDto: CreateClientDto) => {
    onSubmit(clientDto)
  }
  useImperativeHandle(ref, () => ({
    submit() {
      handleSubmit(beforeSubmit)()
    },
  }))

  return (
    <Grid container spacing={2}>
      <Grid item xs={5}>
        <TextInput input="actionName" inputRef={register} errors={errors} />
      </Grid>
      <EcSpaceHorizontal />
      <Grid item xs={2}>
        <CheckBoxInput
          input="status"
          label="Status"
          inputRef={register}
          control={control}
        />
      </Grid>
      <EcSpaceVertical size={90} />
      <Grid item xs={12}>
        <Grid item xs={4} style={{ position: 'relative' }}>
          {/* <Box style={{ height: '100%', width: 2, backgroundColor: 'red', position: 'absolute', left: 20, bottom:-40 }}>
            </Box> */}

          <LineVertical left={20} bottom={-40} />

          <SelectInput
            input="when"
            label="WHEN"
            inputRef={register}
            control={control}
            options={macroWhenSelect}
          />
        </Grid>
        <EcSpaceVertical size={25} />
        {/* {
            listAction?.map((v, i) => {
              return (
                <Box ml={3}>
                  <EcSpaceVertical size={10} />


                </Box>
              )
            })
          } */}

        <Action
          register={register}
          control={control}
          errors={errors}
          // remove={(action) => {
          //   setListAction(filter(listAction, o => o !== action))
          // }}
        />
        {/* <Box ml={3}>
            <EcSpaceVertical size={10} />
            <Button
              color="primary"
              startIcon={<AddCircleOutlineIcon />}
              onClick={() => {
                setListAction(listAction => [...listAction, `'Action' ${listAction.length + 1}`]);
              }}
            >Add Sub-condition</Button>
          </Box> */}
      </Grid>

      {/* ----------------------------------------------------------------------------------------------- */}

      <EcSpaceVertical size={200} />
      <Grid item xs={12}>
        <Box display="flex">
          <Grid item xs={4}>
            <SelectInput
              input="perform"
              label="PERFORM THE FOLLOWING"
              inputRef={register}
              control={control}
              options={macroPerformSelect}
              onChange={(e) => {
                setPerformSelected(e.target.value)
              }}
            />
          </Grid>
          <EcSpaceHorizontal size={30} />
          <Grid item xs={3}>
            <SelectInput
              input="select"
              label="Select..."
              inputRef={register}
              control={control}
              options={macroPerformSelect}
              onChange={(e) => {
                setPerformSelected(e.target.value)
              }}
            />
          </Grid>
        </Box>

        <EcSpaceVertical size={25} />
        <Box ml={3}>
          {performSelected === '1' && (
            <SendEmail
              // action={v}
              register={register}
              control={control}
              errors={errors}
              // remove={(action) => {
              //   setListAction(filter(listAction, o => o !== action))
              // }}
            />
          )}

          {performSelected === '2' && (
            <SendTextMeassage
              // action={v}
              register={register}
              control={control}
              errors={errors}
              remove={(action) => {
                setListAction(filter(listAction, (o) => o !== action))
              }}
            />
          )}

          {performSelected === '3' && (
            <PushToPacs
              // action={v}
              register={register}
              control={control}
              errors={errors}
              remove={(action) => {
                setListAction(filter(listAction, (o) => o !== action))
              }}
            />
          )}

          {(performSelected === '4' || performSelected === '5') && (
            <ShareExam
              // action={v}
              register={register}
              control={control}
              errors={errors}
              remove={(action) => {
                setListAction(filter(listAction, (o) => o !== action))
              }}
            />
          )}

          {performSelected === '6' && (
            <PreFetch
              // action={v}
              register={register}
              control={control}
              errors={errors}
              remove={(action) => {
                setListAction(filter(listAction, (o) => o !== action))
              }}
            />
          )}
        </Box>
      </Grid>
    </Grid>
  )
})
