import { useRef } from 'react'

import { Box, Dialog, DialogContent } from '@material-ui/core'
import { DialogTitle } from '@components/titles'
import { Button } from '@components/buttons'

import { AvailableAppointment } from '../schedule'
import { EcSpaceVertical } from '@utils/Spacing'
import { AvailableAppointmentForm } from '../forms/AppointmentForm/AvailableAppointmentForm'

type AvailableAppointmentDialogProps = {
  onClose: () => void
  open: boolean
  // onSubmit: (values: CreateClientDto) => void
}

export function AvailableAppointmentDialog({
  onClose,
  open,
}: AvailableAppointmentDialogProps) {
  const childRef = useRef<any>()

  // const handleOnSubmit = () => {
  //   if (childRef && childRef.current) {
  //     childRef.current.submit()
  //   }
  // }

  // const beforeSubmit = (newClient: CreateClientDto) => {
  //   onSubmit(newClient)
  //   onClose()
  // }

  return (
    <Dialog
      open={open}
      // onClose={onClose}
      aria-labelledby="form-dialog-title"
      maxWidth="lg"
      scroll="body"
    >
      <DialogTitle
        title="app.schedule.dialogs.available-appointment.title"
        onClose={onClose}
        format
      />
      <DialogContent
      //style={{height:600}}
      >
        <Box padding={1} minWidth={800}>
          <AvailableAppointmentForm ref={childRef} />
        </Box>
        <Box mt={3.5} width={782.5} display="flex" justifyContent="flex-end">
          <Button
            style={{ width: 475 }}
            size="large"
            text="Search"
            color="primary"
          />
        </Box>
        <EcSpaceVertical size={50} />
        <AvailableAppointment />
        <EcSpaceVertical size={25} />
      </DialogContent>

      {/* <DialogActions>
        <Button
          format
          text="forms.buttons.submit"
          //onClick={handleOnSubmit}
          color="primary"
        //loading={loading}
        />
      </DialogActions> */}
    </Dialog>
  )
}
