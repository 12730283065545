import { useState } from 'react'

import { Button, Grid, IconButton } from '@material-ui/core'
// ICON
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import { SelectInput, TextInput } from '@components/inputs'

import { IActionPerformProps } from '../common/Interface'
import { macroPerformPushToPacsSelect } from '@utils/constants'
import { EcSpaceVertical } from '@utils/Spacing'

export const PushToPacs = ({
  register,
  control,
  errors,
}: // remove,
// action
IActionPerformProps) => {
  // const [actionSelected, setActionSelected] = React.useState('1');
  const [listPushTo, setListPushTo] = useState<Array<any>>([])

  return (
    <>
      {listPushTo?.map((value, i) => {
        return (
          <>
            <OnePushToPacs
              key={i}
              register={register}
              control={control}
              errors={errors}
            />
            <EcSpaceVertical />
          </>
        )
      })}
      <EcSpaceVertical />
      <Button
        color="primary"
        startIcon={<AddCircleOutlineIcon />}
        onClick={() => {
          setListPushTo((listPushTo) => [
            ...listPushTo,
            `push ${listPushTo.length + 1}`,
          ])
        }}
      >
        Add Sub-rule
      </Button>
    </>
  )
}

const OnePushToPacs = ({
  register,
  control,
  errors,
  remove,
  action,
}: IActionPerformProps) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={3}>
        <SelectInput
          defaultValue="1"
          input="action"
          label="Action"
          inputRef={register}
          control={control}
          options={macroPerformPushToPacsSelect}
        />
      </Grid>

      <Grid item xs={4}>
        <TextInput input="actionName" inputRef={register} errors={errors} />
      </Grid>

      <IconButton
        aria-label="delete"
        color="secondary"
        onClick={() => {
          if (remove && action) {
            remove(action)
          }
        }}
      >
        <HighlightOffIcon />
      </IconButton>
    </Grid>
  )
}
