import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import {
  AdminDto,
  DoctorDto,
  PatientDto,
  SuperAdminDto,
  UserDto,
} from '@services/api'

export type Account = PatientDto | DoctorDto | AdminDto | SuperAdminDto
type authState = {
  isAuth: boolean
  captcha: {
    data: string
    code: string
  }
  user?: UserDto
  profile?: Account
  language: string
  signFail: {
    failure: number
    period: number
    window: number
    timeout: number
  }
}

const initialState: authState = {
  isAuth: false,
  user: undefined,
  captcha: {
    data: '',
    code: '',
  },
  profile: undefined,
  language: navigator.language.split(/[-_]/)[0],
  signFail: {
    failure: 0,
    period: 0,
    window: 0,
    timeout: 0,
  },
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginSuccess: (state, action: PayloadAction<UserDto>) => {
      const user = action.payload
      state.isAuth = true
      state.user = user
      state.captcha = initialState.captcha
    },
    setProfile: (state, action: PayloadAction<Account>) => {
      state.profile = action.payload
    },
    setLanguage: (state, action: PayloadAction<string>) => {
      state.language = action.payload
    },
    setSignFail: (state, action: PayloadAction<any>) => {
      state.signFail = action.payload
    },
    setCaptcha: (state, action: PayloadAction<any>) => {
      state.captcha = action.payload
    },
    logout: () => initialState,
  },
})

export const {
  loginSuccess,
  logout,
  setCaptcha,
  setProfile,
  setLanguage,
  setSignFail,
} = authSlice.actions

export default authSlice.reducer
