import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'

import { yupResolver } from '@hookform/resolvers/yup'
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Icon,
  makeStyles,
} from '@material-ui/core'
import { PasswordInput } from '@components/inputs'
import { Button } from '@components/buttons'

import { UserDto } from '@services/api'
import { updatePassword } from '@state/actions/authActions'
import { resetPasswordSchema } from '@utils/schemas'
import Text from '../Text'

const useStyles = makeStyles(() => ({
  section: {
    flexGrow: 1,
  },
}))

type Values = {
  newPassword: string
  confirmNewPassword: string
}
export type UpdatePasswordFormProps = {
  user: UserDto
}

export function UpdatePasswordForm({ user }: UpdatePasswordFormProps) {
  const dispatch = useDispatch()
  const classes = useStyles()
  const { register, handleSubmit, errors } = useForm<Values>({
    resolver: yupResolver(resetPasswordSchema) as any,
  })

  const onSubmit = (values: Values) => {
    dispatch(updatePassword({ body: values, id: user.id }))
  }
  return (
    <Card className={classes.section}>
      <CardHeader
        title={
          <Grid container direction="row" alignItems="center" spacing={2}>
            <Grid item>
              <Icon className="fa fa-lock" />
            </Grid>
            <Grid item>
              <Text text="app.profile.sections.[4].title" format />
            </Grid>
          </Grid>
        }
      />
      <Divider />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <PasswordInput
              input="newPassword"
              inputRef={register}
              errors={errors}
              autoComplete="new-password"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <PasswordInput
              input="confirmNewPassword"
              inputRef={register}
              errors={errors}
              autoComplete="new-password"
            />
          </Grid>
        </Grid>
      </CardContent>

      <CardActions>
        <Grid container direction="row" justifyContent="flex-end">
          <Grid item>
            <Button
              text="app.profile.sections.[0].button"
              onClick={handleSubmit(onSubmit)}
              format
              color="primary"
            />
          </Grid>
        </Grid>
      </CardActions>
    </Card>
  )
}
