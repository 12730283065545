import { useRef } from 'react'

import {
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
} from '@material-ui/core'
import { Button } from '@components/buttons'
import { DialogTitle } from '@components/titles'

import { CalendarPreferencesForm } from '../forms'

type AppointPreConfig = {
  // row: Row
  // onChange: any
  // onApplyChanges: () => void
  onCancelChanges: () => void
  open: boolean
}

export function CalendarPreferencesDialog({
  // row,
  // onChange,
  // onApplyChanges,
  onCancelChanges,
  open,
}: AppointPreConfig) {
  const childRef = useRef<any>()

  const handleOnSubmit = () => {
    if (childRef && childRef.current) {
      childRef.current.submit()
      // onCancelChanges()
    }
  }
  return (
    <Dialog
      open={open}
      onClose={onCancelChanges}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle
        title="Calendar display preference"
        onClose={onCancelChanges}
      />
      <Divider />

      <DialogContent>
        <Grid container>
          <CalendarPreferencesForm ref={childRef} />
        </Grid>
      </DialogContent>
      <Divider />

      <DialogActions>
        <Button
          format
          text="forms.buttons.submit"
          onClick={handleOnSubmit}
          color="primary"
        />
      </DialogActions>
    </Dialog>
  )
}
