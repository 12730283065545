import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'

import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Icon,
  makeStyles,
} from '@material-ui/core'
import { TextInput } from '@components/inputs'
import { Button } from '@components/buttons'
import { yupResolver } from '@hookform/resolvers/yup'

import { updateEmail } from '@state/actions/authActions'
import { updateEmailSchema } from '@utils/schemas'
import Text from '../Text'

const useStyles = makeStyles(() => ({
  section: {
    flexGrow: 1,
  },
}))
type UpdateEmailFormProps = {
  email?: string
}

type Values = {
  email: string
}
export function UpdateEmailForm({ email }: UpdateEmailFormProps) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { register, handleSubmit, errors } = useForm<any>({
    resolver: yupResolver(updateEmailSchema),
    defaultValues: {
      email: email,
    },
  })

  const onSubmit = ({ email }: Values) => {
    dispatch(
      updateEmail({
        body: { email },
      }),
    )
  }
  return (
    <Card className={classes.section}>
      <CardHeader
        title={
          <Grid container direction="row" alignItems="center" spacing={2}>
            <Grid item>
              <Icon className="fa fa-envelope" />
            </Grid>
            <Grid item>
              <Text text="app.profile.sections.[5].title" format />
            </Grid>
          </Grid>
        }
      />
      <Divider />
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={6} sm={6}>
            <TextInput input="email" inputRef={register} errors={errors} />
          </Grid>
        </Grid>
      </CardContent>

      <CardActions>
        <Grid container direction="row" justifyContent="flex-end">
          <Grid item>
            <Button
              text="app.profile.sections.[0].button"
              onClick={handleSubmit(onSubmit)}
              format
              color="primary"
            />
          </Grid>
        </Grid>
      </CardActions>
    </Card>
  )
}
