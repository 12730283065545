import {
  CreatePhoneAuthMethod,
  LocalUserRegisterFromMailDto,
  LocalUserRegisterPatientDto,
  LocalUserResetPasswordDto,
  PhoneVerifyRequest,
  // PatientDto,
} from '@services/api'
import { LocalUserUpdatePasswordDto, LoginDto } from '@services/api'
import {
  FirstConnectionDto,
  LocalUserForgetPasswordDto,
} from '@services/extendedType'
import { AuthActions } from '../actions'

export const getCaptcha = () => ({
  type: AuthActions.GET_CAPTCHA_REQUEST,
})

export const login = (params: {
  /** requestBody */
  body?: LoginDto
}) => ({
  type: AuthActions.LOGIN_REQUEST,
  payload: params,
})

export const logout = () => ({
  type: AuthActions.LOGOUT_REQUEST,
})

export const firstConnexion = (params: FirstConnectionDto) => ({
  type: AuthActions.FIRST_CONNEXION_REQUEST,
  payload: {
    accessionNumber: params.accessionNumber,
    birthDate: params.birthDate.toString(),
  },
})

export const registerUser = (params: {
  /** requestBody */
  body?: LocalUserRegisterPatientDto
}) => ({
  type: AuthActions.REGISTER_REQUEST,
  payload: params,
})

export const verifyEmail = (params: {
  /** requestBody */
  body?: LocalUserRegisterFromMailDto
}) => ({
  type: AuthActions.VERIFY_EMAIL_REQUEST,
  payload: params,
})

export const forgottenPassword = (params: LocalUserForgetPasswordDto) => ({
  type: AuthActions.FORGOTTEN_PASSWORD_REQUEST,
  payload: params,
})

export const resetPassword = (params: {
  id: number
  body: LocalUserResetPasswordDto
}) => ({
  type: AuthActions.RESET_PASSWORD_REQUEST,
  payload: params,
})

export const updatePassword = (params: {
  /**  */
  id: number
  /** requestBody */
  body?: LocalUserUpdatePasswordDto
}) => ({
  type: AuthActions.PUT_PASSWORD_REQUEST,
  payload: params,
})

export const updatePhone = (params: {
  /** requestBody */
  body?: CreatePhoneAuthMethod
}) => ({
  type: AuthActions.PUT_PHONE_REQUEST,
  payload: params,
})

export const updateEmail = (params: {
  /** requestBody */
  body?: LocalUserForgetPasswordDto
}) => ({
  type: AuthActions.PUT_EMAIL_REQUEST,
  payload: params,
})

export const verifyCode = (params: {
  /** requestBody */
  body?: PhoneVerifyRequest
}) => ({
  type: AuthActions.VERIFY_CODE_REQUEST,
  payload: params,
})
