import React, { useState, useEffect } from 'react'
import Button from '@material-ui/core/Button'
import { Dialog, DialogContent, DialogActions, Box } from '@material-ui/core'
import { alpha } from '@material-ui/core/styles'
import { DialogTitle } from '../titles/DialogTitle'
import Typography from '@material-ui/core/Typography'
import { EcSpaceHorizontal } from '@utils/Spacing'
import Checkbox from '@material-ui/core/Checkbox'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '@state/reducers/root'
import {
  setResources,
  setRoomsSelected,
  setTypeRoomSelected,
} from '@state/reducers/appointementReducer'
import { filter, map } from 'lodash'
import { roomsInit, typeRoom } from '../schedule/params'

export interface ConfirmationDialogRawProps {
  open: boolean
  onClose: (value?: string) => void
}

export function SelectRoomsDialog(props: ConfirmationDialogRawProps) {
  const { roomsSelected, typeRoomSelected } = useSelector(
    ({ appointement }: RootState) => appointement,
  )

  const [newRoomsSelected, setNewRoomsSelected] =
    useState<Array<any>>(roomsSelected)
  const [currentTypeRoomSelected, setCurrentTypeRoomSelected] =
    useState(typeRoomSelected)

  const dispatch = useDispatch()

  const { onClose, open, ...other } = props

  const handleCancel = () => {
    onClose()
  }

  const handleOk = () => {
    dispatch(setResources(newRoomsSelected))
    dispatch(setRoomsSelected(newRoomsSelected))
    dispatch(setTypeRoomSelected(currentTypeRoomSelected))

    onClose()
  }

  const onSelectedRoomChange = (id: number) => {
    if (newRoomsSelected.includes(id)) {
      //inside

      setNewRoomsSelected((newRoomsSelected) =>
        filter(newRoomsSelected, (o) => o !== id),
      )
    } else {
      setNewRoomsSelected((newRoomsSelected) => [...newRoomsSelected, id])
    }
  }

  useEffect(() => {
    if (currentTypeRoomSelected) {
      setNewRoomsSelected(
        map(
          filter(
            roomsInit,
            (o) => o.typeRoom === Number(currentTypeRoomSelected),
          ),
          (value) => {
            return String(value.id)
          },
        ),
      )
    }
  }, [currentTypeRoomSelected])

  useEffect(() => {
    if (currentTypeRoomSelected) {
      setNewRoomsSelected(
        map(
          filter(roomsInit, (o) => o.typeRoom === currentTypeRoomSelected),
          (value) => value.id,
        ),
      )
    }
  }, [currentTypeRoomSelected])

  const setTypeRoomSelectedLocal = (id: number) => {
    if (currentTypeRoomSelected === id) {
      setCurrentTypeRoomSelected(undefined)
    } else {
      setCurrentTypeRoomSelected(id)
    }
  }

  return (
    <Dialog
      maxWidth="md"
      aria-labelledby="confirmation-dialog-title"
      open={open}
      {...other}
    >
      <DialogTitle title="Selection Of Rooms" onClose={handleOk} />

      <DialogContent dividers style={{ width: 500 }}>
        <Box pb={2} display="flex" justifyContent="flex-start" flexWrap="wrap">
          {typeRoom?.map((value, i) => {
            return (
              <Button
                key={i}
                variant={
                  currentTypeRoomSelected === Number(value.id)
                    ? 'contained'
                    : 'outlined'
                }
                color="primary"
                style={{ margin: 7.5 }}
                onClick={() => {
                  setTypeRoomSelectedLocal(Number(value.id))
                }}
              >
                {value.label}
              </Button>
            )
          })}
        </Box>
        {roomsInit?.map((value, i) => {
          return (
            <OneRoomSelect
              key={i}
              text={value.text}
              color={value.color}
              id={value.id}
              defaultChecked={newRoomsSelected?.includes(value.id)}
              onSelectedChange={onSelectedRoomChange}
            />
          )
        })}
      </DialogContent>

      <DialogActions>
        <Button autoFocus onClick={handleCancel} color="primary">
          Cancel
        </Button>
        <Button onClick={handleOk} color="primary">
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export interface OneRoomSelectProps {
  text: string
  color: any
  id: number
  defaultChecked?: boolean
  onSelectedChange: (id: number) => void
}

const OneRoomSelect = (props: OneRoomSelectProps) => {
  const { text, color, defaultChecked, id, onSelectedChange } = props

  //const [checked, setChecked] = React.useState(defaultChecked)

  const handleChange = () => {
    // console.log('cool classe', event.target.checked)
    //setChecked(event.target.checked)
    onSelectedChange(id)
  }

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      borderRadius={4}
      bgcolor="#f1f1f1"
      p={0.5}
      pl={2}
      mb={1.5}
      style={{ cursor: 'pointer' }}
    >
      <Box display="flex" alignItems="center">
        <Box
          width={20}
          height={20}
          bgcolor={alpha(color[400], 1)}
          borderRadius="50%"
        ></Box>

        <EcSpaceHorizontal size={20} />

        <Typography variant="h5">{text}</Typography>
      </Box>

      <Checkbox
        checked={defaultChecked}
        onChange={handleChange}
        //defaultChecked={defaultChecked}
        size="medium"
        inputProps={{ 'aria-label': 'primary checkbox' }}
      />
    </Box>
  )
}
