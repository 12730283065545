export enum AuthActions {
  LOGOUT_REQUEST = 'LOGOUT_REQUEST',
  LOGOUT_SUCCESS = 'LOGOUT_SUCCESS',
  LOGOUT_ERROR = 'LOGOUT_ERROR',
  LOGIN_REQUEST = 'LOGIN_REQUEST',
  LOGIN_SUCCESS = 'LOGIN_SUCCESS',
  LOGIN_ERROR = 'LOGIN_ERROR',
  FIRST_CONNEXION_REQUEST = 'FIRST_CONNEXION_REQUEST',
  FIRST_CONNEXION_SUCCESS = 'FIRST_CONNEXION_SUCCESS',
  FIRST_CONNEXION_ERROR = 'FIRST_CONNEXION_ERROR',
  REGISTER_REQUEST = 'REGISTER_REQUEST',
  REGISTER_SUCCESS = 'REGISTER_SUCCESS',
  REGISTER_ERROR = 'REGISTER_ERROR',
  FORGOTTEN_PASSWORD_REQUEST = 'FORGOTTEN_PASSWORD_REQUEST',
  FORGOTTEN_PASSWORD_SUCCESS = 'FORGOTTEN_PASSWORD_SUCCESS',
  FORGOTTEN_PASSWORD_ERROR = 'FORGOTTEN_PASSWORD_ERROR',
  RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST',
  RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS',
  RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR',
  GET_CAPTCHA_REQUEST = 'GET_CAPTCHA_REQUEST',
  GET_CAPTCHA_SUCCESS = 'GET_CAPTCHA_SUCCESS',
  GET_CAPTCHA_ERROR = 'GET_CAPTCHA_ERROR',
  PUT_EMAIL_SUCCESS = 'PUT_EMAIL_SUCCESS',
  PUT_EMAIL_ERROR = 'PUT_EMAIL_ERROR',
  PUT_EMAIL_REQUEST = 'PUT_EMAIL_REQUEST',
  VERIFY_EMAIL_SUCCESS = 'VERIFY_EMAIL_SUCCESS',
  VERIFY_EMAIL_ERROR = 'VERIFY_EMAIL_ERROR',
  VERIFY_EMAIL_REQUEST = 'VERIFY_EMAIL_REQUEST',
  PUT_PASSWORD_SUCCESS = 'PUT_PASSWORD_SUCCESS',
  PUT_PASSWORD_ERROR = 'PUT_PASSWORD_ERROR',
  PUT_PASSWORD_REQUEST = 'PUT_PASSWORD_REQUEST',
  PUT_PROFILE_REQUEST = 'PUT_PROFILE_REQUEST',
  PUT_PROFILE_SUCCESS = 'PUT_PROFILE_SUCCESS',
  PUT_PROFILE_ERROR = 'PUT_PROFILE_ERROR',
  PUT_PHONE_SUCCESS = 'PUT_PHONE_SUCCESS',
  PUT_PHONE_ERROR = 'PUT_PHONE_ERROR',
  PUT_PHONE_REQUEST = 'PUT_PHONE_REQUEST',
  VERIFY_CODE_REQUEST = 'VERIFY_CODE_REQUEST',
  VERIFY_CODE_SUCCESS = 'VERIFY_CODE_SUCCESS',
  VERIFY_CODE_ERROR = 'VERIFY_CODE_ERROR',
}

export enum PatientActions {
  GET_PATIENTS_REQUEST = 'GET_PATIENTS_REQUEST',
  GET_PATIENTS_SUCCESS = 'GET_PATIENTS_SUCCESS',
  GET_PATIENTS_ERROR = 'GET_PATIENTS_ERROR',
  GET_PATIENT_REQUEST = 'GET_PATIENT_REQUEST',
  GET_PATIENT_SUCCESS = 'GET_PATIENT_SUCCESS',
  GET_PATIENT_ERROR = 'GET_PATIENT_ERROR',
  PUT_PATIENT_SUCCESS = 'PUT_PATIENT_SUCCESS',
  PUT_PATIENT_REQUEST = 'PUT_PATIENT_REQUEST',
  PUT_PATIENT_ERROR = 'PUT_PATIENT_ERROR',
  PUT_REFERRING_DOCTOR_REQUEST = 'PUT_REFERRING_DOCTOR_REQUEST',
  PUT_REFERRING_DOCTOR_SUCCESS = 'PUT_REFERRING_DOCTOR_SUCCESS',
  PUT_REFERRING_DOCTOR_ERROR = 'PUT_REFERRING_DOCTOR_ERROR',
  OPEN_VIEWER_REQUEST = 'OPEN_VIEWER_REQUEST',
  OPEN_VIEWER_SUCCESS = 'OPEN_VIEWER_SUCCESS',
  OPEN_VIEWER_ERROR = 'OPEN_VIEWER_ERROR',
}

export enum DoctorActions {
  GET_DOCTORS_REQUEST = 'GET_DOCTORS_REQUEST',
  GET_DOCTORS_SUCCESS = 'GET_DOCTORS_SUCCESS',
  GET_DOCTORS_ERROR = 'GET_DOCTORS_ERROR',
  GET_DOCTOR_REQUEST = 'GET_DOCTOR_REQUEST',
  GET_DOCTOR_SUCCESS = 'GET_DOCTOR_SUCCESS',
  GET_DOCTOR_ERROR = 'GET_DOCTOR_ERROR',
  POST_DOCTOR_SUCCESS = 'POST_DOCTOR_SUCCESS',
  POST_DOCTOR_REQUEST = 'POST_DOCTOR_REQUEST',
  POST_DOCTOR_ERROR = 'POST_DOCTOR_ERROR',
  PUT_DOCTOR_SUCCESS = 'PUT_DOCTOR_SUCCESS',
  PUT_DOCTOR_REQUEST = 'PUT_DOCTOR_REQUEST',
  PUT_DOCTOR_ERROR = 'PUT_DOCTOR_ERROR',
  GET_AUTOCOMPLETE_DOCTORS_REQUEST = 'GET_AUTOCOMPLETE_DOCTORS_REQUEST',
  GET_AUTOCOMPLETE_DOCTORS_SUCCESS = 'GET_AUTOCOMPLETE_DOCTORS_SUCCESS',
  GET_AUTOCOMPLETE_DOCTORS_ERROR = 'GET_AUTOCOMPLETE_DOCTORS_ERROR',
}

export enum EquipmentActions {
  GET_EQUIPMENTS_REQUEST = 'GET_EQUIPMENTS_REQUEST',
  GET_EQUIPMENTS_SUCCESS = 'GET_EQUIPMENTS_SUCCESS',
  GET_EQUIPMENTS_ERROR = 'GET_EQUIPMENTS_ERROR',
  GET_EQUIPMENT_REQUEST = 'GET_EQUIPMENT_REQUEST',
  GET_EQUIPMENT_SUCCESS = 'GET_EQUIPMENT_SUCCESS',
  GET_EQUIPMENT_ERROR = 'GET_EQUIPMENT_ERROR',
  POST_EQUIPMENT_SUCCESS = 'POST_EQUIPMENT_SUCCESS',
  POST_EQUIPMENT_REQUEST = 'POST_EQUIPMENT_REQUEST',
  POST_EQUIPMENT_ERROR = 'POST_EQUIPMENT_ERROR',
  PUT_EQUIPMENT_SUCCESS = 'PUT_EQUIPMENT_SUCCESS',
  PUT_EQUIPMENT_REQUEST = 'PUT_EQUIPMENT_REQUEST',
  PUT_EQUIPMENT_ERROR = 'PUT_EQUIPMENT_ERROR',
  GET_AUTOCOMPLETE_EQUIPMENTS_REQUEST = 'GET_AUTOCOMPLETE_EQUIPMENTS_REQUEST',
  GET_AUTOCOMPLETE_EQUIPMENTS_SUCCESS = 'GET_AUTOCOMPLETE_EQUIPMENTS_SUCCESS',
  GET_AUTOCOMPLETE_EQUIPMENTS_ERROR = 'GET_AUTOCOMPLETE_EQUIPMENTS_ERROR',
}

export enum StaffActions {
  GET_STAFFS_REQUEST = 'GET_STAFFS_REQUEST',
  GET_STAFFS_SUCCESS = 'GET_STAFFS_SUCCESS',
  GET_STAFFS_ERROR = 'GET_STAFFS_ERROR',
  GET_STAFF_REQUEST = 'GET_STAFF_REQUEST',
  GET_STAFF_SUCCESS = 'GET_STAFF_SUCCESS',
  GET_STAFF_ERROR = 'GET_STAFF_ERROR',
  POST_STAFF_SUCCESS = 'POST_STAFF_SUCCESS',
  POST_STAFF_REQUEST = 'POST_STAFF_REQUEST',
  POST_STAFF_ERROR = 'POST_STAFF_ERROR',
  PUT_STAFF_SUCCESS = 'PUT_STAFF_SUCCESS',
  PUT_STAFF_REQUEST = 'PUT_STAFF_REQUEST',
  PUT_STAFF_ERROR = 'PUT_STAFF_ERROR',
}

export enum AdminActions {
  GET_ADMINS_REQUEST = 'GET_ADMINS_REQUEST',
  GET_ADMINS_SUCCESS = 'GET_ADMINS_SUCCESS',
  GET_ADMINS_ERROR = 'GET_ADMINS_ERROR',
  GET_ADMIN_REQUEST = 'GET_ADMIN_REQUEST',
  GET_ADMIN_SUCCESS = 'GET_ADMIN_SUCCESS',
  GET_ADMIN_ERROR = 'GET_ADMIN_ERROR',
  POST_ADMIN_SUCCESS = 'POST_ADMIN_SUCCESS',
  POST_ADMIN_REQUEST = 'POST_ADMIN_REQUEST',
  POST_ADMIN_ERROR = 'POST_ADMIN_ERROR',
  PUT_ADMIN_SUCCESS = 'PUT_ADMIN_SUCCESS',
  PUT_ADMIN_REQUEST = 'PUT_ADMIN_REQUEST',
  PUT_ADMIN_ERROR = 'PUT_ADMIN_ERROR',
}

export enum SuperAdminActions {
  GET_SUPER_ADMINS_REQUEST = 'GET_SUPER_ADMINS_REQUEST',
  GET_SUPER_ADMINS_SUCCESS = 'GET_SUPER_ADMINS_SUCCESS',
  GET_SUPER_ADMINS_ERROR = 'GET_SUPER_ADMINS_ERROR',
  GET_SUPER_ADMIN_REQUEST = 'GET_SUPER_ADMIN_REQUEST',
  GET_SUPER_ADMIN_SUCCESS = 'GET_SUPER_ADMIN_SUCCESS',
  GET_SUPER_ADMIN_ERROR = 'GET_SUPER_ADMIN_ERROR',
  POST_SUPER_ADMIN_SUCCESS = 'POST_SUPER_ADMIN_SUCCESS',
  POST_SUPER_ADMIN_REQUEST = 'POST_SUPER_ADMIN_REQUEST',
  POST_SUPER_ADMIN_ERROR = 'POST_SUPER_ADMIN_ERROR',
  PUT_SUPER_ADMIN_SUCCESS = 'PUT_SUPER_ADMIN_SUCCESS',
  PUT_SUPER_ADMIN_REQUEST = 'PUT_SUPER_ADMIN_REQUEST',
  PUT_SUPER_ADMIN_ERROR = 'PUT_SUPER_ADMIN_ERROR',
  GET_PROGRESS = 'GET_PROGRESS',
  CANCEL_PROGRESS = 'CANCEL_PROGRESS',
}

export enum ClientActions {
  GET_CLIENTS_REQUEST = 'GET_CLIENTS_REQUEST',
  GET_CLIENTS_SUCCESS = 'GET_CLIENTS_SUCCESS',
  GET_CLIENTS_ERROR = 'GET_CLIENTS_ERROR',
  GET_CLIENT_REQUEST = 'GET_CLIENT_REQUEST',
  GET_CLIENT_SUCCESS = 'GET_CLIENT_SUCCESS',
  GET_CLIENT_ERROR = 'GET_CLIENT_ERROR',
  POST_CLIENT_SUCCESS = 'POST_CLIENT_SUCCESS',
  POST_CLIENT_REQUEST = 'POST_CLIENT_REQUEST',
  POST_CLIENT_ERROR = 'POST_CLIENT_ERROR',
  PUT_CLIENT_SUCCESS = 'PUT_CLIENT_SUCCESS',
  PUT_CLIENT_REQUEST = 'PUT_CLIENT_REQUEST',
  PUT_CLIENT_ERROR = 'PUT_CLIENT_ERROR',
}

export enum SiteActions {
  GET_SITES_REQUEST = 'GET_SITES_REQUEST',
  GET_SITES_SUCCESS = 'GET_SITES_SUCCESS',
  GET_SITES_ERROR = 'GET_SITES_ERROR',
  GET_SITE_REQUEST = 'GET_SITE_REQUEST',
  GET_SITE_SUCCESS = 'GET_SITE_SUCCESS',
  GET_SITE_ERROR = 'GET_SITE_ERROR',
  POST_SITE_SUCCESS = 'POST_SITE_SUCCESS',
  POST_SITE_REQUEST = 'POST_SITE_REQUEST',
  POST_SITE_ERROR = 'POST_SITE_ERROR',
  PUT_SITE_SUCCESS = 'PUT_SITE_SUCCESS',
  PUT_SITE_REQUEST = 'PUT_SITE_REQUEST',
  PUT_SITE_ERROR = 'PUT_SITE_ERROR',
  GET_AUTOCOMPLETE_SITES_REQUEST = 'GET_AUTOCOMPLETE_SITES_REQUEST',
  GET_AUTOCOMPLETE_SITES_SUCCESS = 'GET_AUTOCOMPLETE_SITES_SUCCESS',
  GET_AUTOCOMPLETE_SITES_ERROR = 'GET_AUTOCOMPLETE_SITES_ERROR',
}

export enum StudyActions {
  GET_STUDIES_REQUEST = 'GET_STUDIES_REQUEST',
  GET_STUDIES_SUCCESS = 'GET_STUDIES_SUCCESS',
  GET_STUDIES_ERROR = 'GET_STUDIES_ERROR',
  GET_STUDY_REQUEST = 'GET_STUDY_REQUEST',
  GET_STUDY_SUCCESS = 'GET_STUDY_SUCCESS',
  GET_STUDY_ERROR = 'GET_STUDY_ERROR',
  POST_STUDY_REQUEST = 'POST_STUDY_REQUEST',
  POST_STUDY_SUCCESS = 'POST_STUDY_SUCCESS',
  POST_STUDY_ERROR = 'POST_STUDY_ERROR',
  PUT_STUDY_REQUEST = 'PUT_STUDY_REQUEST',
  PUT_STUDY_SUCCESS = 'PUT_STUDY_SUCCESS',
  PUT_STUDY_ERROR = 'PUT_STUDY_ERROR',
  POST_SHARE_STUDY_REQUEST = 'POST_SHARE_STUDY_REQUEST',
  POST_SHARE_STUDY_SUCCESS = 'POST_SHARE_STUDY_SUCCESS',
  POST_SHARE_STUDY_ERROR = 'POST_SHARE_STUDY_ERROR',
  POST_UNSHARE_STUDY_REQUEST = 'POST_UNSHARE_STUDY_REQUEST',
  POST_UNSHARE_STUDY_SUCCESS = 'POST_UNSHARE_STUDY_SUCCESS',
  POST_UNSHARE_STUDY_ERROR = 'POST_UNSHARE_STUDY_ERROR',
  GET_SHARES_STUDY_REQUEST = 'GET_SHARES_STUDY_REQUEST',
  GET_SHARES_STUDY_SUCCESS = 'GET_SHARES_STUDY_SUCCESS',
  GET_SHARES_STUDY_ERROR = 'GET_SHARES_STUDY_ERROR',
  POST_UPLOAD_ORDER_REQUEST = 'POST_UPLOAD_ORDER_REQUEST',
  POST_UPLOAD_ORDER_SUCCESS = 'POST_UPLOAD_ORDER_SUCCESS',
  POST_UPLOAD_ORDER_ERROR = 'POST_UPLOAD_ORDER_ERROR',
  POST_DOWNLOAD_ORDER_REQUEST = 'POST_DOWNLOAD_ORDER_REQUEST',
  POST_DOWNLOAD_ORDER_SUCCESS = 'POST_DOWNLOAD_ORDER_SUCCESS',
  POST_DOWNLOAD_ORDER_ERROR = 'POST_DOWNLOAD_ORDER_ERROR',
  POST_DELETE_ORDER_REQUEST = 'POST_DELETE_ORDER_REQUEST',
  POST_DELETE_ORDER_SUCCESS = 'POST_DELETE_ORDER_SUCCESS',
  POST_DELETE_ORDER_ERROR = 'POST_DELETE_ORDER_ERROR',
  POST_DOWNLOAD_REPORT_REQUEST = 'POST_DOWNLOAD_REPORT_REQUEST',
  POST_DOWNLOAD_REPORT_SUCCESS = 'POST_DOWNLOAD_REPORT_SUCCESS',
  POST_DOWNLOAD_REPORT_ERROR = 'POST_DOWNLOAD_REPORT_ERROR',
  GET_DOWNLOAD_IMAGE_REQUEST = 'GET_DOWNLOAD_IMAGE_REQUEST',
  GET_DOWNLOAD_IMAGE_SUCCESS = 'GET_DOWNLOAD_IMAGE_SUCCESS',
  GET_DOWNLOAD_IMAGE_ERROR = 'GET_DOWNLOAD_IMAGE_ERROR',
}

export enum LogActions {
  GET_LOGS_REQUEST = 'GET_LOGS_REQUEST',
  GET_LOGS_SUCCESS = 'GET_LOGS_SUCCESS',
  GET_LOGS_ERROR = 'GET_LOGS_ERROR',
}

export enum FinessActions {
  GET_FINESS_REQUEST = 'GET_FINESS_REQUEST',
  GET_FINESS_SUCCESS = 'GET_FINESS_SUCCESS',
  GET_FINESS_ERROR = 'GET_FINESS_ERROR',
  GET_AUTOCOMPLETE_FINESS_REQUEST = 'GET_AUTOCOMPLETE_FINESS_REQUEST',
  GET_AUTOCOMPLETE_FINESS_SUCCESS = 'GET_AUTOCOMPLETE_FINESS_SUCCESS',
  GET_AUTOCOMPLETE_FINESS_ERROR = 'GET_AUTOCOMPLETE_FINESS_ERROR',
  POST_FINESS_REQUEST = 'POST_FINESS_REQUEST',
  POST_FINESS_SUCESS = 'POST_FINESS_REQUEST',
  POST_FINESS_ERROR = 'POST_FINESS_REQUEST',
}

export enum RppsActions {
  GET_RPPS_REQUEST = 'GET_RPPS_REQUEST',
  GET_RPPS_SUCCESS = 'GET_RPPS_SUCCESS',
  GET_RPPS_ERROR = 'GET_RPPS_ERROR',
  GET_AUTOCOMPLETE_RPPS_REQUEST = 'GET_AUTOCOMPLETE_RPPS_REQUEST',
  GET_AUTOCOMPLETE_RPPS_SUCCESS = 'GET_AUTOCOMPLETE_RPPS_SUCCESS',
  GET_AUTOCOMPLETE_RPPS_ERROR = 'GET_AUTOCOMPLETE_RPPS_ERROR',
  POST_RPPS_REQUEST = 'POST_RPPS_REQUEST',
  POST_RPPS_SUCESS = 'POST_RPPS_REQUEST',
  POST_RPPS_ERROR = 'POST_RPPS_REQUEST',
}

export enum AlertActions {
  ENQUEUE_SNACKBAR = 'ENQUEUE_SNACKBAR',
  CLOSE_SNACKBAR = 'CLOSE_SNACKBAR',
  REMOVE_SNACKBAR = 'REMOVE_SNACKBAR',
}

export enum ErrorActions {
  INTERNAL_ERROR = 'INTERNAL_ERROR',
  INTERNET_ERROR = 'INTERNET_ERROR',
}

export enum UserActions {
  OPEN_VIEWER_SUCCESS = 'OPEN_VIEWER_SUCCESS',
  OPEN_VIEWER_REQUEST = 'OPEN_VIEWER_REQUEST',
  OPEN_VIEWER_ERROR = 'OPEN_VIEWER_ERROR',
}

export enum AppointementActions {
  SCHEDULER_STATE_CHANGE_ACTION = 'SCHEDULER_STATE_CHANGE',
}
