import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'

import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
} from '@material-ui/core'
import { Button } from '@components/buttons'
import { TextInput } from '@components/inputs'
import { yupResolver } from '@hookform/resolvers/yup'

import { verifyCode } from '@state/actions/authActions'
import { phoneCodeSchema } from '@utils/schemas'
import Text from '../Text'

type ConfirmPhoneDialogProps = {
  onClose: () => void
  open: boolean
}

type Values = {
  code: string
}
export function ConfirmPhoneDialog({ onClose, open }: ConfirmPhoneDialogProps) {
  const dispatch = useDispatch()
  const { handleSubmit, register, errors } = useForm<Values>({
    resolver: yupResolver(phoneCodeSchema),
  })

  const onSubmit = (values: Values) => {
    dispatch(
      verifyCode({
        body: { verificationCode: values.code },
      }),
    )
  }
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      maxWidth="sm"
    >
      <DialogContent>
        <Box px={5} py={3} textAlign="center">
          <Text text={'Enter your verification code'} />
          {/* <Text text={`We have sent a message to phone number ending in ******${}`} /> */}
        </Box>
        <Box px={5} py={2}>
          <TextInput inputRef={register} input="code" errors={errors} />
        </Box>
      </DialogContent>
      <Divider />

      <DialogActions>
        <Grid container justifyContent="space-around">
          <Button
            format
            text="forms.buttons.submit"
            onClick={handleSubmit(onSubmit)}
            color="primary"
          />
        </Grid>
      </DialogActions>
    </Dialog>
  )
}
