import { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { useForm } from 'react-hook-form'

import { yupResolver } from '@hookform/resolvers/yup'
import { Grid, makeStyles } from '@material-ui/core'

import { addClientSchema } from '@utils/schemas'

const useStyles = makeStyles(() => ({
  container: {
    paddingTop: 15,
    paddingBottom: 15,
  },

  oneHeader: {
    backgroundColor: '#f1f1f1',
    padding: 5,
    width: '14%',
    textAlign: 'center',
    borderRadius: 4,
    fontWeight: 400,
    fontSize: 16,
  },
}))

type OneHoursGroup = {
  name: string
  startHour: { value: string | number; label: string }[]
  endHour: { value: string | number; label: string }[]
  isActive: boolean
  control: any
}

type Values = {
  Morning: OneHoursGroup
  Afternoon: OneHoursGroup
  Evening: OneHoursGroup
  Night: OneHoursGroup
}

export const ConfigRoomGroupForm = forwardRef((_, ref) => {
  const { handleSubmit, errors, control, watch } = useForm<Values>({
    resolver: yupResolver(addClientSchema) as any,
    defaultValues: {},
  })

  const classes = useStyles()

  console.log(watch())
  console.log(errors)

  const onSubmit = () => {
    // console.log(values)
    // const newClient: ClientDto = {
    //   name: values.startDate,
    //   siretNumber: values.salle.substring(0, values.salle.length - 5),
    //   sirenNumber: values.salle,
    //   companyStatus: values.room,
    //   streetAddress: values.doctor,
    //   postalCode: values.preferedDoctor,
    //   city: values.type,
    //   state: values.type,
    //   country: values.study,
    //   email: values.comments,
    //   phoneNumber: values.emergency,
    //   //fax: values.hospitalized,
    //  // sites: [],
    // }
    // dispatch(addClient({ body: newClient }))
  }
  useImperativeHandle(ref, () => ({
    submit() {
      handleSubmit(onSubmit)()
    },
  }))

  return (
    <Grid container spacing={1} className={classes.container}>
      <Grid container justifyContent="space-between" direction="row">
        {headerConfigRoom.map((value, i) => (
          <span key={i} className={classes.oneHeader}>
            {value}
          </span>
        ))}
      </Grid>
      <Grid container justifyContent="space-between" direction="column">
        {dataConfigHours.map((value, i) => {
          return (
            <OneConfigHours
              key={i}
              control={control}
              name={value.name}
              startHour={value.startHours}
              endHour={value.endHours}
              isActive={value.active}
            />
          )
        })}
      </Grid>
    </Grid>
  )
})

const OneConfigHours = ({ name }: OneHoursGroup) => {
  return (
    <Grid
      container
      className="one-room-group"
      justifyContent="space-between"
      direction="row"
    >
      <span className="name">{name}</span>
      {[0, 1, 2, 3, 4].map((i, index) => {
        return <OnOffRoom status={i % 2 === 0 ? true : false} key={index} />
      })}
    </Grid>
  )
}

type OnOffRoomType = {
  status: boolean
}

const OnOffRoom = ({ status }: OnOffRoomType) => {
  const [statusState, setStatusState] = useState(status)

  useEffect(() => {
    setStatusState(status)
  }, [status])
  return (
    <div
      className="on-off-room"
      style={{ backgroundColor: statusState ? '#80cbc4' : '#e57373' }}
      onClick={() => {
        setStatusState(!statusState)
      }}
    ></div>
  )
}

const dataConfigHours = [
  {
    name: 'Morning',
    startHours: [
      { value: 1, label: '8h' },
      { value: 2, label: '9h' },
      { value: 3, label: '10h' },
      { value: 4, label: '11h' },
    ],
    endHours: [
      { value: 1, label: '9h' },
      { value: 2, label: '10h' },
      { value: 3, label: '11h' },
      { value: 4, label: '12h' },
    ],

    active: true,
  },

  {
    name: 'Afternoon',
    startHours: [
      { value: 1, label: '12h' },
      { value: 2, label: '13h' },
      { value: 3, label: '14h' },
      { value: 4, label: '15h' },
    ],
    endHours: [
      { value: 1, label: '13h' },
      { value: 2, label: '14h' },
      { value: 3, label: '15h' },
      { value: 4, label: '16h' },
    ],

    active: true,
  },

  {
    name: 'Evening',
    startHours: [
      { value: 1, label: '8h' },
      { value: 2, label: '9h' },
      { value: 3, label: '10h' },
      { value: 4, label: '11h' },
    ],
    endHours: [
      { value: 1, label: '9h' },
      { value: 2, label: '10h' },
      { value: 3, label: '11h' },
      { value: 4, label: '12h' },
    ],

    active: true,
  },

  {
    name: 'Night',
    startHours: [
      { value: 1, label: '8h' },
      { value: 2, label: '9h' },
      { value: 3, label: '10h' },
      { value: 4, label: '11h' },
    ],
    endHours: [
      { value: 1, label: '9h' },
      { value: 2, label: '10h' },
      { value: 3, label: '11h' },
      { value: 4, label: '12h' },
    ],

    active: true,
  },
]

const headerConfigRoom = ['Room', 'Mon', 'Tue', 'Web', 'Thu', 'Fri']
