import { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { useForm } from 'react-hook-form'

// import { yupResolver } from '@hookform/resolvers/yup'
import { useDispatch } from 'react-redux'

import { Grid } from '@material-ui/core'
import { AutocompletFullNameInput, SelectInput } from '@components/inputs'
import { Button } from '@components/buttons'

import { shareStudy } from '@state/actions/studyActions'
import {
  studySharingExpirations,
  studySharingPermissions,
} from '@utils/constants'
import { SharedStudyPermission } from '@services/api'

type Values = {
  doctorId: number
  expiration: number
  permission: SharedStudyPermission
}
type AddSharingFormProps = {
  studyId: number
}
export const AddSharingForm = forwardRef(
  ({ studyId }: AddSharingFormProps, ref) => {
    const dispatch = useDispatch()
    const [doctor, setDoctor] = useState<any>()

    const { register, handleSubmit, errors, control, setValue, watch } =
      useForm<Values>({
        // resolver: yupResolver(shareStudySchema) as any,
        defaultValues: {
          expiration: 1,
          permission: SharedStudyPermission.VIEW,
        },
      })
    console.log(watch())
    console.log(errors)

    const onSubmit = (values: Values) => {
      const now = new Date()
      dispatch(
        shareStudy({
          body: {
            studyId,
            endDate: new Date(
              now.setDate(now.getDate() + values.expiration * 7),
            ),
            permission: values.permission,
            doctorId: values.doctorId,
          },
        }),
      )
    }
    useImperativeHandle(ref, () => ({
      submit() {
        handleSubmit(onSubmit)()
      },
    }))

    useEffect(() => {
      register({ name: 'doctorId' })
      if (doctor) setValue('doctorId', doctor.id)
    }, [doctor, register, setValue])

    return (
      <Grid container spacing={3}>
        <Grid
          item
          xs={6}
          style={{ display: 'flex', alignItems: 'center', marginTop: 2 }}
        >
          <AutocompletFullNameInput onSelect={setDoctor} />
        </Grid>
        <Grid item xs={3}>
          <SelectInput
            options={studySharingPermissions}
            control={control}
            input="permission"
            errors={errors}
          />
        </Grid>
        <Grid item xs={3}>
          <SelectInput
            options={studySharingExpirations}
            control={control}
            input="expiration"
            errors={errors}
          />
        </Grid>
        <Grid item xs={12} container justifyContent="flex-end">
          <Button
            text="Submit"
            onClick={handleSubmit(onSubmit)}
            color="primary"
          />
        </Grid>
      </Grid>
    )
  },
)
