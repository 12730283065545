import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'

import { yupResolver } from '@hookform/resolvers/yup'
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  Icon,
  makeStyles,
} from '@material-ui/core'
// import { useDispatch } from 'react-redux'
import { DateInput, SelectInput, TextInput } from '@components/inputs'
import { Button } from '@components/buttons'
import { ZipFranceInput } from '@components/inputs'

import { RootState } from '@state/reducers/root'
import {
  AdminDto,
  DoctorDto,
  PatientDto,
  Sex,
  SuperAdminDto,
} from '@services/api'
import Text from '../Text'
import { sex } from '@utils/constants'
import { updateGeneralInfoSchema } from '@utils/schemas'
// import { updateGeneralInfo } from '@state/actions/authActions'

const useStyles = makeStyles(() => ({
  section: {
    flexGrow: 1,
  },
}))

type Values = {
  firstName: string
  lastName: string
  sex: Sex
  streetAddress: string
  zipCode: string
  city: string
  state: string
  country: string
  birthPlace: string
  birthDate: Date
  ssn?: string
}

export type GeneralInfoFormProps = {
  profile: PatientDto | DoctorDto | AdminDto | SuperAdminDto
}

export function GeneralInfoForm({ profile }: GeneralInfoFormProps) {
  const classes = useStyles()
  // const dispatch = useDispatch()
  const user = useSelector(({ auth }: RootState) => auth.user)

  const { register, handleSubmit, errors, control, getValues } =
    useForm<Values>({
      defaultValues: profile,
      resolver: yupResolver(updateGeneralInfoSchema) as any,
    })

  const onSubmit = () => {
    // dispatch(
    //   updateGeneralInfo({ body: { ...profile, ...values }, id: profile.id }),
    // )
  }

  useEffect(() => {
    const address = getValues('address')
    console.log(address)
    // return () => {
    //     cleanup
    // }
  }, [getValues])

  return (
    <Card className={classes.section}>
      <CardHeader
        title={
          <Grid container direction="row" alignItems="center" spacing={2}>
            <Grid item>
              <Icon className="fa fa-file-alt" />
            </Grid>
            <Grid item>
              <Text text="app.profile.sections.[0].title" format />
            </Grid>
          </Grid>
        }
      />
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={5}>
            <TextInput input="firstName" inputRef={register} errors={errors} />
          </Grid>
          <Grid item xs={12} sm={5}>
            <TextInput input="lastName" inputRef={register} errors={errors} />
          </Grid>
          <Grid item xs={12} sm={2}>
            <SelectInput
              input="sex"
              control={control}
              errors={errors}
              options={sex}
            />
          </Grid>
          {/* <Grid item xs={12}>
            <AddressInput name="address" control={control} />
          </Grid> */}
          <Grid item xs={12} sm={3}>
            <TextInput input="street" inputRef={register} errors={errors} />
          </Grid>
          <Grid item xs={12} sm={3}>
            {/* <TextInput input="postalCode" inputRef={register} errors={errors} /> */}
            <ZipFranceInput name="postalCode" control={control} />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextInput input="city" inputRef={register} errors={errors} />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextInput input="country" inputRef={register} errors={errors} />
          </Grid>
          <Grid item xs={12} sm={4}>
            <DateInput input="birthDate" control={control} errors={errors} />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextInput input="birthPlace" inputRef={register} errors={errors} />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextInput input="ssn" inputRef={register} errors={errors} />
          </Grid>
        </Grid>
      </CardContent>
      {user?.role !== 'patient' && (
        <CardActions>
          <Button
            text="app.profile.sections.[0].button"
            onClick={handleSubmit(onSubmit)}
            format
            color="primary"
          />
        </CardActions>
      )}
    </Card>
  )
}
