import { useState, Fragment } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'

import { Grid, TableCell, TableRow } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { makeStyles } from '@material-ui/core/styles'

// import { RootState } from '@state/reducers/root'
import { Button } from '@components/buttons'
import { SelectInput, TextInput } from '@components/inputs'

import { setNotification } from '@state/reducers/testReducers'

const useStyles = makeStyles(() => ({
  section: {
    flexGrow: 1,
  },
}))

type NotificationTemplateDialogProps = {
  field: {
    id: number
    name: string
    subject: string
    content: string
    version: string
  }
}

type Value = {
  id: number
  name: string
  subject: string
  content: string
  version: string
}

export function NotificationTemplateDialog({
  field,
}: NotificationTemplateDialogProps) {
  const { id, name, subject, content, version } = field
  const classes = useStyles()
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false)
  // const { notification } = useSelector(({ test }: RootState) => ({
  //   notification: test.notifications.filter((not) => not.id === field.id),
  // }))
  const { handleSubmit, control, register } = useForm<Value>({
    defaultValues: {
      id: id,
      name: name,
      subject: subject,
      content: content,
      version: version,
    },
  })
  const onSubmit = (values: Value) => {
    if (
      values.name === '' ||
      values.subject === '' ||
      values.version === '' ||
      values.content === ''
    ) {
      alert('Thanks for feeding all fields')
    } else {
      values = { ...values, id: id }
      dispatch(setNotification(values))
      handleClose()
    }
  }

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const getForm = () => (
    <form className={classes.section} noValidate>
      {/* {id === 0 ? (
        <TextInput input={'name'} inputRef={register} />
      ) : (
        <DialogTitle id="max-width-dialog-title">Edit - {name}</DialogTitle>
      )} */}
      <DialogTitle id="max-width-dialog-title">Edit - {name}</DialogTitle>

      <DialogContent>
        <Grid container spacing={3}>
          <Grid xs={12} item>
            {/* <InputLabel htmlFor="max-width">Select template to use</InputLabel> */}
            <SelectInput
              options={[
                { label: 'Original', value: 'Original' },
                { label: 'Custom', value: 'Custom' },
                { label: 'Disabled', value: 'Disabled' },
              ]}
              input="version"
              control={control}
            />
          </Grid>
          <Grid xs={12} item>
            <TextInput input="subject" inputRef={register} />
          </Grid>
          <Grid xs={12} item>
            <TextInput input="content" inputRef={register} />
          </Grid>
        </Grid>
      </DialogContent>
    </form>
  )

  // const handleFullWidthChange = (
  //   event: React.ChangeEvent<HTMLInputElement>,
  // ) => {
  //   setFullWidth(event.target.checked)
  // }

  return (
    <Fragment>
      {id === 0 ? (
        <Button
          onClick={handleClickOpen}
          text="Add Notification"
          color="primary"
        />
      ) : (
        <TableRow key={field.name}>
          <TableCell component="th" scope="row">
            {field.name}
          </TableCell>
          <TableCell align="left">{field.subject}</TableCell>
          <TableCell align="left">{field.version}</TableCell>
          <TableCell align="center">
            <b
              style={{ color: 'blue', cursor: 'pointer' }}
              onClick={handleClickOpen}
            >
              Edit
            </b>
          </TableCell>
        </TableRow>
      )}
      <Dialog fullWidth maxWidth="md" open={open} onClose={handleClose}>
        {open && getForm()}
        <DialogActions>
          <Button
            onClick={handleSubmit(onSubmit)}
            text="Save Changes"
            color="primary"
          />
          <Button onClick={handleClose} text="Close" color="secondary" />
        </DialogActions>
      </Dialog>
    </Fragment>
  )
}
