import { useState } from 'react'

import { Grid } from '@material-ui/core'
// ICON

import { SelectInput } from '@components/inputs'

import { IActionPerformProps } from '../common/Interface'
import {
  macroPerformShareExp,
  macroPerformSharePermission,
} from '@utils/constants'

export const ShareExam = ({
  register,
  control,
}: // errors,
// remove,
// action
IActionPerformProps) => {
  const [actionSelected, setActionSelected] = useState('1')
  console.log(actionSelected)
  // const [listPushTo, setListPushTo] = React.useState<Array<any>>([]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={2}>
        <SelectInput
          defaultValue="1"
          input="permission"
          label="Permission"
          inputRef={register}
          control={control}
          options={macroPerformSharePermission}
          onChange={(e) => {
            setActionSelected(e.target.value)
          }}
        />
      </Grid>

      <Grid item xs={2}>
        <SelectInput
          defaultValue="1"
          input="dateExp"
          label="Date Exp."
          inputRef={register}
          control={control}
          options={macroPerformShareExp}
          onChange={(e) => {
            setActionSelected(e.target.value)
          }}
        />
      </Grid>
    </Grid>
  )
}
