import { Box, Dialog, DialogActions, DialogContent } from '@material-ui/core'
import { DialogTitle } from '@components/titles'
import { Button } from '@components/buttons'

import { UpdateAppointmentForm } from '../forms/AppointmentForm/UpdateAppointmentForm'

type UpdateAppointmentDialoggProps = {
  onClose: () => void
  open: boolean
  // onSubmit: (values: CreateClientDto) => void
  // slots: any
}

export function UpdateAppointmentDialog({
  onClose,
  open,
}: //slots
UpdateAppointmentDialoggProps) {
  // const handleOnSubmit = () => {
  //   if (childRef && childRef.current) {
  //     childRef.current.submit()
  //   }
  // }

  // const beforeSubmit = (newClient: CreateClientDto) => {
  //   onSubmit(newClient)
  //   onClose()
  // }

  return (
    <Dialog
      open={open}
      // onClose={onClose}
      aria-labelledby="form-dialog-title"
      maxWidth="md"
      scroll="body"
    >
      <DialogTitle
        title="app.schedule.dialogs.update-appointment.title"
        onClose={onClose}
        format
      />
      <DialogContent>
        <Box maxWidth={750}>
          <UpdateAppointmentForm />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          format
          text="forms.buttons.submit"
          //onClick={handleOnSubmit}
          color="primary"
          //loading={loading}
        />
      </DialogActions>
    </Dialog>
  )
}
