import { Box, Grid } from '@material-ui/core'
import { TextEditor, TextInput } from '@components/inputs'

import { IActionPerformProps } from '../common/Interface'
import { EcSpaceVertical } from '@utils/Spacing'

export const SendEmail = ({ register, errors }: IActionPerformProps) => {
  // const [actionSelected, setActionSelected] = React.useState('1');
  //  const [listSendMessage, setlistSendMessage] = React.useState<Array<any>>(['sendM 1']);

  return (
    <Box display="flex" flexDirection="column" width="100%">
      <Grid item xs={7}>
        <TextInput input="emailSubject" inputRef={register} errors={errors} />
      </Grid>
      <EcSpaceVertical size={15} />
      <Grid item xs={7}>
        <TextEditor />
      </Grid>
    </Box>
  )
}
