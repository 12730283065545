import { forwardRef, useImperativeHandle } from 'react'
import { useForm } from 'react-hook-form'

import { yupResolver } from '@hookform/resolvers/yup'
import { Grid, Theme, createStyles, makeStyles } from '@material-ui/core'
import { RadioInput, SwitchInput } from '@components/inputs'

import { addClientSchema } from '@utils/schemas'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      paddingTop: 15,
    },
    input: {
      maxWidth: 300,
    },
    paper: {
      height: 140,
      width: 100,
    },
    control: {
      padding: theme.spacing(2),
    },
    radioGroupClass: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    labelClass: {
      margin: '0px !important',
      marginRight: '40px !important',
    },
  }),
)

type Values = {
  startDate: string
  endDate: string
  salle: string
  room: string
  doctor: string
  preferedDoctor: string
  type: string
  study: string
  emergency: string
  hospitalized?: string
  referringDoctor?: string
  comments: string
  waitingList: string
}

export const CalendarPreferencesForm = forwardRef((_, ref) => {
  const classes = useStyles()

  const { handleSubmit, control, errors, watch } = useForm<Values>({
    resolver: yupResolver(addClientSchema) as any,
    defaultValues: {
      room: '',
    },
  })
  console.log(watch())
  console.log(errors)

  const onSubmit = () => {}
  useImperativeHandle(ref, () => ({
    submit() {
      handleSubmit(onSubmit)()
    },
  }))

  return (
    <Grid container className={classes.root} spacing={2}>
      <Grid item xs={12}>
        <RadioInput
          control={control}
          input="orderInfo"
          label="Order of appointment info"
          defaultValue="patientExam"
          radioGroupClass={classes.radioGroupClass}
          labelClass={classes.labelClass}
          options={[
            { label: 'Patient -> Exam', value: 'patientExam' },
            { label: 'Exam -> Patient', value: 'examPatient' },
          ]}
        />
      </Grid>

      <Grid item xs={12}>
        <RadioInput
          control={control}
          input="appearanceAppointment"
          label="Appearance appointment"
          defaultValue="statusColor"
          radioGroupClass={classes.radioGroupClass}
          labelClass={classes.labelClass}
          options={[
            { label: 'Status color', value: 'statusColor' },
            { label: 'Exam color', value: 'examColor' },
          ]}
        />{' '}
      </Grid>

      <Grid item xs={12}>
        <RadioInput
          control={control}
          input="defaultView"
          label="Default view"
          defaultValue="week"
          radioGroupClass={classes.radioGroupClass}
          labelClass={classes.labelClass}
          options={[
            { label: 'Day', value: 'day' },
            { label: 'Week', value: 'week' },
          ]}
        />{' '}
      </Grid>

      <Grid item xs={12}>
        <SwitchInput label="Display the end time of the appointment" />{' '}
      </Grid>
      <Grid item xs={12}>
        <SwitchInput label="Blinking new appointment" />{' '}
      </Grid>
      <Grid item xs={12}>
        <SwitchInput label="Blinking modified appointment" />{' '}
      </Grid>
      <Grid item xs={12}>
        <SwitchInput label="Hide practitioner" />{' '}
      </Grid>
      <Grid item xs={12}>
        <SwitchInput label="Direct access to views" />{' '}
      </Grid>
    </Grid>
  )
})
