import { Filter, Sorting } from '@devexpress/dx-react-grid'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { DoctorDto, RppsDto } from '@services/api'
import { PaginatedDto } from '@services/extendedType'

type doctorState = {
  doctors: {
    totalCount: number
    datas: DoctorDto[]
  }
  rpps: {
    totalCount: number
    datas: RppsDto[]
  }
  filters: Filter[]
  sorting: Sorting[]
  currentPage: number
  pageSize: number
  selection: (string | number)[]
  selected?: DoctorDto
  columnOrder: string[]
  options: any[]
}

const initialState: doctorState = {
  doctors: {
    totalCount: 0,
    datas: [],
  },
  rpps: {
    totalCount: 0,
    datas: [],
  },
  options: [],
  filters: [],
  sorting: [{ columnName: 'plannedDate', direction: 'asc' }],
  currentPage: 0,
  pageSize: 10,
  selection: [],
  selected: undefined,
  columnOrder: [
    'plannedDate',
    'modality',
    'title',
    'referringDoctor',
    'open',
    'actions',
  ],
}

const doctorSlice = createSlice({
  name: 'doctor',
  initialState,
  reducers: {
    setDoctors: (state, action: PayloadAction<PaginatedDto<DoctorDto>>) => {
      state.doctors = action.payload
    },
    removeDoctors: (state) => {
      state.doctors = initialState.doctors
    },
    addDoctor: (state, action: PayloadAction<DoctorDto>) => {
      state.doctors.datas = [...state.doctors.datas, action.payload]
      state.doctors.totalCount += 1
    },
    updateDoctor: (state, action: PayloadAction<DoctorDto>) => {
      state.doctors.datas = state.doctors.datas.map((doctor) =>
        doctor.id === action.payload.id ? action.payload : doctor,
      )
    },
    setRpps: (state, action: PayloadAction<PaginatedDto<RppsDto>>) => {
      state.rpps = action.payload
    },
    removeRpps: (state) => {
      state.rpps = initialState.rpps
    },
    setFilters: (state, action: PayloadAction<Filter[]>) => {
      state.filters = action.payload
    },
    setSorting: (state, action: PayloadAction<Sorting[]>) => {
      state.sorting = action.payload
    },
    setPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload
    },
    setCurrentPage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload
    },
    setSelection: (state, action: PayloadAction<(string | number)[]>) => {
      const selection = action.payload
      const index = selection[0] as number
      state.selected = state.doctors.datas[index]
    },
    setSelected: (state, action: PayloadAction<DoctorDto>) => {
      state.selected = action.payload
    },
    setColumnOrder: (state, action: PayloadAction<string[]>) => {
      state.columnOrder = action.payload
    },
    setOptions: (state, action: PayloadAction<any[]>) => {
      state.options = action.payload
    },
    cleanOptions: (state) => {
      state.options = initialState.options
    },
    cleanDoctor: () => initialState,
  },
})

export const {
  setDoctors,
  removeDoctors,
  addDoctor,
  updateDoctor,
  setRpps,
  removeRpps,
  setFilters,
  setSorting,
  setPageSize,
  setCurrentPage,
  setSelection,
  setSelected,
  setColumnOrder,
  setOptions,
  cleanDoctor,
  cleanOptions,
} = doctorSlice.actions

export default doctorSlice.reducer
